// extracted by mini-css-extract-plugin
export var wrap = "line-item-module--wrap--2rFXi";
export var itemHeader = "line-item-module--item-header--1JTPD";
export var priceColumn = "line-item-module--priceColumn--1ad14";
export var totals = "line-item-module--totals--38d7K";
export var variant = "line-item-module--variant--31P0F";
export var meta = "line-item-module--meta--2hnPO";
export var remove = "line-item-module--remove--2ZvaX buttons--button--1nSrK";
export var itemQuantity = "line-item-module--itemQuantity--3NzWn";
export var variantProperty = "line-item-module--variantProperty--2yQtv";
export var priceLabel = "line-item-module--priceLabel--1_BZn";