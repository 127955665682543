import * as React from "react"
import { Layout } from "../components/layout"
import { StoreContext } from "../context/store-context"
import { LineItem } from "../components/line-item"
import { formatPrice } from "../utils/format-price"
import {
  wrap,
  table,
  totals,
  grandTotal,
  summary,
  summaryItem,
  checkoutButton,
  labelColumn,
  emptyStateContainer,
  emptyStateHeading,
  title,
} from "./cart.module.css"

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  return (
    <Layout>
      <div className={wrap}>
        {emptyCart ? (
          <div className={emptyStateContainer}>
            <h1 className={emptyStateHeading}>Your cart is empty</h1>
            <p>
              Looks like you haven’t found anything yet.
            </p>
          </div>
        ) : (
          <div>
            <h1 className={title}>Bag</h1>
              <div className={table}>
                {checkout.lineItems.map((item) => (
                  <LineItem item={item} key={item.id} />
                ))}
              <div className={summary}>
                <div className={summaryItem}>
                  <div className={labelColumn}>Subtotal</div>
                  <div className={totals}>
                    {formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}
                  </div>
                </div>
                <div className={summaryItem}>
                  <div className={labelColumn}>Taxes</div>
                  <div className={totals}>
                    {formatPrice(
                      checkout.totalTaxV2.currencyCode,
                      checkout.totalTaxV2.amount
                    )}
                  </div>
                </div>
                <div className={summaryItem}>
                  <div className={labelColumn}>Shipping</div>
                  <div className={totals}>Calculated at checkout</div>
                </div>
                <div className={grandTotal}>
                    <div className={labelColumn}>Total Price</div>
                    <div className={totals}>
                      {formatPrice(
                        checkout.totalPriceV2.currencyCode,
                        checkout.totalPriceV2.amount
                      )}
                  </div>
                </div>
              </div>
              <button
                onClick={handleCheckout}
                disabled={loading}
                className={checkoutButton}
              >
                Proceed to checkout
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
