import * as React from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { GatsbyImage } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import {
  wrap,
  meta,
  itemHeader,
  itemQuantity,
  remove,
  variant,
  priceLabel,
  variantProperty,
  variantValue,
  totals,
  priceColumn,
} from "./line-item.module.css"

export function LineItem({ item }) {
  const {
    removeLineItem,
    checkout,
    updateLineItem,
  } = React.useContext(StoreContext)
  const [quantity] = React.useState(item.quantity)

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  }
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  )

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value) => uli(value), [])

  // const handleQuantityChange = (value) => {
  //   if (value !== "" && Number(value) < 1) {
  //     return
  //   }
  //   setQuantity(value)
  //   if (Number(value) >= 1) {
  //     debouncedUli(value)
  //   }
  // }

  // function doIncrement() {
  //   handleQuantityChange(Number(quantity || 0) + 1)
  // }

  // function doDecrement() {
  //   handleQuantityChange(Number(quantity || 0) - 1)
  // }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 300,
        height: 428,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage.src]
  )

  return (
    <div className={wrap}>
        {image && (
          <GatsbyImage
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        )}
        <div className={meta}>
          <div className={itemHeader}>
            <div className={itemQuantity}>
              {quantity}X
            </div>
            <h2>{item.title}</h2>
          </div>
          <div className={variant}>
            <div className={variantProperty}>Size</div>
            <div className={variantValue}>
              {item.variant.title === "Default Title" ? "" : item.variant.title}
            </div>
          </div>
          <div className={priceColumn}>
            <span className={priceLabel}>Item</span> {price}</div>
          <div className={totals}>
            <span className={priceLabel}>
              Subtotal
            </span>
            {subtotal}</div>
          <button className={remove} onClick={handleRemove}>Remove</button>
      </div>
    </div>
  )
}
